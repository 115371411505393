/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import {
  formatDate,
  formatDistanceToNow,
} from "../../../../../../utils/date_helpers";
import styles from "./AuctionDetailsComponents.module.scss";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import BasicTableHeader from "../../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import { useAppDispatch } from "../../../../../../store";
import { User } from "../../../../../../types/User";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
  selectUserInfoLoading,
} from "../../../../../../store/user/selectors";
import {
  getIndividualAuction,
  StoreAuctionPartial,
  selectWinningBid,
  SelectWinnerRequestProps,
} from "../../../../../../store/individualAuction/actions";
import {
  isIndividualAuctionLoading,
  selectIndividualAuction,
  selectIndividualAuctionError,
  selectIndividualAuctionId,
  selectSuccessfullySelectedWinner,
} from "../../../../../../store/individualAuction/selectors";
import { resetState } from "../../../../../../store/individualAuction/reducer";
import { Auction } from "../../../../../../types/Auction";
import { selectStore } from "../../../../../../store/stores/selectors";
import {
  getStoreById,
  StoreProps,
} from "../../../../../../store/stores/actions";
import {
  selectAllBids,
  selectBidLoading,
} from "../../../../../../store/bidActivity/selectors";
import { Bid, fetchBids } from "../../../../../../store/bidActivity/actions";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../../../../store/user/actions";
import { Market } from "../../../../../../types/Market";
import DefaultButton from "../../../../../Buttons/DefaultButton";
import FullScreenLoading from "../../../../../Loading/FullScreenLoading";
import {
  getCurrencyLocaleValue,
  sendToast,
} from "../../../../../../utils/helpers";

const AuctionConfirm = ({ individualPage = false }) => {
  const dispatch = useAppDispatch();
  const auctionId = useSelector((state) =>
    selectIndividualAuctionId(state)
  ) as number;
  const [showEndAuctionConfirm, setShowEndAuctionConfirm] = useState(false);
  const [selectedWinningBid, setSelectedWinningBid] = useState(null);
  const loading = useSelector(
    (state) =>
      isIndividualAuctionLoading(state) ||
      selectUserInfoLoading(state) ||
      selectBidLoading(state)
  ) as boolean;
  const user = useSelector((state) => selectUserInfo(state)) as User;
  const currentStore = useSelector((state) => selectStore(state)) as StoreProps;
  const individualAuctionData = useSelector((state) =>
    selectIndividualAuction(state)
  ) as Auction;
  const successfullySelectedWinner = useSelector((state) =>
    selectSuccessfullySelectedWinner(state)
  );
  const bids = useSelector((state) => selectAllBids(state)) as Bid[];
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];

  const error = useSelector((state) => selectIndividualAuctionError(state));

  useEffect(() => {
    if (error) {
      sendToast(error, { type: "error" });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      dispatch(getStoreById(user.data.last_store_id));
    }
    if (auctionId && user) {
      const storeAuctionPartial = {
        storeId: user.data.last_store_id,
        auctionId,
      } as StoreAuctionPartial;
      dispatch(getIndividualAuction(storeAuctionPartial));
      dispatch(fetchBids(storeAuctionPartial));
    }
  }, [user]);

  useEffect(() => {
    if (!user && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
    if (auctionId && user) {
      const storeAuctionPartial = {
        storeId: user.data.last_store_id,
        auctionId,
      } as StoreAuctionPartial;
      dispatch(getIndividualAuction(storeAuctionPartial));
      dispatch(fetchBids(storeAuctionPartial));
    }
  }, []);

  useEffect(() => {
    if (!availableMarkets) {
      dispatch(getUserInstagramAccounts());
    }
  }, []);

  useEffect(() => {
    if (successfullySelectedWinner) {
      // Refetch auction after succesfully selecting winner
      dispatch(resetState());
      const props = {
        storeId: currentStore.id,
        auctionId: auctionId || individualAuctionData.id,
      } as StoreAuctionPartial;
      dispatch(getIndividualAuction(props));
      // Close if coming from confirm
      if (individualPage) {
        navigate(`/dashboard/auctions/${auctionId}/`);
      }
    }
  }, [successfullySelectedWinner]);

  const confirmWinner = () => {
    setShowEndAuctionConfirm(true);
  };

  const handleConfirmEndAuction = () => {
    if (!auctionId) {
      sendToast("No Auction id was found", { type: "error" });
    }
    if (!user) {
      sendToast("No user found, Please refresh and try again.", {
        type: "error",
      });
    }
    const props = {
      auction_id: auctionId,
      store_id: user.data.last_store_id,
    } as SelectWinnerRequestProps;
    if (selectedWinningBid !== "NoWinner" && selectedWinningBid) {
      props.bid_id = selectedWinningBid;
    }
    dispatch(selectWinningBid(props));
  };

  const sortBids = () => {
    const activeBids = bids.filter((bid) => bid.active);
    const notActiveBids = bids.filter((bid) => !bid.active);
    return {
      activeBids,
      deactivatedBids: notActiveBids,
    };
  };

  const handleBack = () => {
    navigate(`/dashboard/auctions/${auctionId}/`);
  };

  return (
    <>
      {loading && <FullScreenLoading />}
      <div className="bg-white w-full h-full flex flex-col">
        <div className="flex flex-row justify-center items-center w-full relative border-b border-solid p-4 border-bidddyLightGray">
          <h4 className=" text-bidddyAltGray font-inter text-base font-medium justify-self-center inline-flex">
            Confirm Winner
          </h4>
          <button
            type="button"
            className="absolute left-4 font-inter text-bidddyPink font-medium"
            onClick={() => handleBack()}
          >
            Back
          </button>
        </div>
        <div className={styles.confirmWinnerContainer}>
          <p className="font-medium font-inter w-full text-left text-base text-bidddyAltGray mb-2">
            Confirm Winning Bid or BIN
          </p>
          <p className="font-normal font-inter text-sm text-bidddyGray">
            To end the auction, select the highest winning bidder or a Buy It
            Now (BIN) offer that you have confirmed payment for.
          </p>
          <BasicTable>
            <BasicTableHeader>
              <BasicTableRow>
                <td colSpan={3}>Activity</td>
              </BasicTableRow>
            </BasicTableHeader>
            <BasicTableBody>
              {sortBids().activeBids.map((bid) => (
                <BasicTableRow hideBorderBottom>
                  <td colSpan={1} className="pb-4">
                    <div
                      key={`bidActivity-${bid.id}-active`}
                      className="flex flex-row items-center mt-0 justify-between"
                    >
                      <div
                        className={classNames([
                          "flex flex-col",
                          {
                            [styles.selectedWinner]:
                              selectedWinningBid === bid.id,
                          },
                        ])}
                      >
                        <a
                          className={styles.bidderName}
                          href={`https://instagram.com/${bid.name}/`}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          @{bid.name}
                        </a>
                        <p
                          className="text-bidddyGray text-xs"
                          title={formatDate(new Date(bid.created_at))}
                        >
                          {formatDistanceToNow(new Date(bid.created_at))} ago
                        </p>
                      </div>
                    </div>
                  </td>
                  <td colSpan={1}>
                    <h3
                      className={`font-inter text-base font-medium ${
                        selectedWinningBid === bid.id
                          ? "text-bidddyPink"
                          : "text-bidddyAltGray"
                      }`}
                    >
                      {bid && bid.bin && !bid.deactivator
                        ? "BIN"
                        : getCurrencyLocaleValue(
                            individualAuctionData.currency,
                            bid.amount
                          )}
                    </h3>
                  </td>
                  <td colSpan={1} onClick={() => setSelectedWinningBid(bid.id)}>
                    {/* add circle */}
                    {selectedWinningBid === bid.id && (
                      <div className={styles.pinkCheckmark} />
                    )}
                    {selectedWinningBid !== bid.id && (
                      <div className={styles.grayCircle} />
                    )}
                  </td>
                </BasicTableRow>
              ))}
            </BasicTableBody>
          </BasicTable>
          <div className={styles.or}>
            <span />
            OR
            <span />
          </div>
          <BasicTable>
            <BasicTableHeader>
              <td colSpan={3} />
            </BasicTableHeader>
            <BasicTableBody>
              <BasicTableRow hideBorderBottom>
                <td colSpan={1} className="pb-4">
                  <div className="flex flex-row items-center mt-0 justify-between w-40">
                    <div
                      className={classNames([
                        "flex flex-col",
                        {
                          [styles.selectedWinner]:
                            selectedWinningBid === "NoWinner",
                        },
                      ])}
                    >
                      <a
                        className={classNames([
                          styles.bidderNameSm,
                          styles.boldSm,
                        ])}
                        rel="noopener noreferrer nofollow"
                      >
                        No winning bidder
                      </a>
                      <p className="text-bidddyGray text-xs">
                        End auction without a winning bidder.
                      </p>
                    </div>
                  </div>
                </td>
                <td colSpan={1}>
                  <h3
                    className={`font-inter text-base font-medium  ${
                      selectedWinningBid === "NoWinner"
                        ? "text-bidddyPink"
                        : "text-bidddyAltGray"
                    } mr-12`}
                  >
                    -
                  </h3>
                </td>
                <td
                  colSpan={1}
                  onKeyDown={() => setSelectedWinningBid("NoWinner")}
                  onClick={() => setSelectedWinningBid("NoWinner")}
                  className="mr-4"
                >
                  {/* add circle */}
                  {selectedWinningBid === "NoWinner" && (
                    <div
                      className={classNames([styles.pinkCheckmark])}
                      style={{ marginRight: "1.25rem" }}
                    />
                  )}
                  {selectedWinningBid !== "NoWinner" && (
                    <div
                      className={classNames([styles.grayCircle])}
                      style={{ marginRight: "0.875rem" }}
                    />
                  )}
                </td>
              </BasicTableRow>
            </BasicTableBody>
          </BasicTable>
        </div>
        <div className={styles.endAuctionButtonModal}>
          <DefaultButton
            disabled={!selectedWinningBid}
            title="CONFIRM"
            onClick={() => confirmWinner()}
          />
        </div>
      </div>
      {showEndAuctionConfirm && selectedWinningBid && (
        <div className={styles.draftModalContainer}>
          <div className={styles.draftPopup}>
            <div className={styles.draftPopupContainer}>
              {selectedWinningBid === "NoWinner" && (
                <p>Are you sure you want to end this auction?</p>
              )}
              {selectedWinningBid !== "NoWinner" && (
                <p>
                  Are you sure you&apos;d like to select{" "}
                  {
                    sortBids().activeBids.filter(
                      (bid) => bid.id === selectedWinningBid
                    )[0].name
                  }{" "}
                  as the winner?
                </p>
              )}
              <button
                type="button"
                className="text-bidddyPink"
                onClick={() => handleConfirmEndAuction()}
              >
                {selectedWinningBid === "NoWinner" ? "End Auction" : "Confirm"}
              </button>
              <button
                type="button"
                className="text-bidddyAltGray"
                onClick={() => setShowEndAuctionConfirm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionConfirm;
